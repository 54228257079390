<template>
  <v-card>
    <v-card-title>かんたんポイント</v-card-title>
    <v-card-text v-if="saved">
      <v-alert type="info">
        ありがとうございました。
        <br />
        現在のポイントは{{ pointOf() }}ポイントです。
        <br />またのご来店をお待ちしております。
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      メールアドレスを入力してポイントを貯めよう！
      <v-text-field v-model="email" label="メールアドレス" outlined></v-text-field>
      <v-btn color="primary" @click="save">OK</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    saved: false,
    email: '',
    points: []
  }),
  computed: {
  },
  methods: {
    pointOf () {
      for (let i = 0; i < this.points.length; i++) {
        const { email, count } = this.points[i]
        if (email === this.email) {
          return count
        }
      }
      return 0
    },
    save () {
      let done = false
      for (let i = 0; i < this.points.length; i++) {
        const { email } = this.points[i]
        if (email === this.email) {
          this.points[i].count += 1
          done = true
          break
        }
      }
      if (!done) {
        this.points.push({ email: this.email, count: 1 })
      }
      const parsed = JSON.stringify(this.points)
      localStorage.setItem('points', parsed)

      this.saved = true
    }
  },
  mounted () {
    if (localStorage.getItem('points')) {
      this.points = JSON.parse(localStorage.getItem('points'))
    }
  },
  destroyed () {
    const parsed = JSON.stringify(this.points)
    localStorage.setItem('points', parsed)
  }
}
</script>
